import { Schema } from "@griddo/core";

export const PUBLICATIONS: Schema.SimpleContentType = {
	dataPacks: ["PUBLICATIONS"],
	title: "Publications (Auto)",
	local: true,
	translate: false,
	editable: true,
	clone: null,
	fromPage: false,
	includedInPageSearch: true,
	schema: {
		searchMapping: {
			title: "title",
			image: "image",
			url: "url",
		},
		fields: [
			{
				type: "TextField",
				title: "Title",
				key: "title",
				readonly: true,
			},
			{
				type: "TextField",
				title: "ID",
				key: "id",
				readonly: true,
			},
			{
				type: "TextField",
				title: "Url",
				key: "url",
				readonly: true,
			},
			{
				type: "TextField",
				title: "Magazine",
				key: "magazine",
				readonly: true,
			},
			{
				key: "image",
				title: "Image",
				type: "ImageField",
			},
			{
				key: "researchGroup",
				title: "Research group",
				placeholder: "Research group",
				type: "MultiCheckSelect",
				source: "RESEARCH_GROUP",
			},
		],
	},
};
