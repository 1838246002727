import type { FC } from "react";

import * as React from "react";

import { ComponentWrapper } from "./ComponentWrapper";
import { SessionProvider } from "../../contexts";
import { useNavigation, useSite } from "../../hooks";

export interface ComponentProps {
	// libComponents: Record<
	// 	string,
	// 	React.FC<Omit<ComponentProps, "libComponents">>
	// >;
	// TODO: remove this any, maybe ths will require refactor the
	// component/componenWrapper/preview components.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	libComponents: Record<string, any>;
	editorID?: number;
	component: string;
	type?: string;
	parentEditorID?: number;
	[key: string]: unknown;
}

/**
 * Griddo core component to render dynamically a component/module (using the
 * `component` prop) from the `libComponents` (a list of React components) with
 * the props we provide to it
 *
 * @example
 * import Hero from './Hero'
 * import CardCollection from './CardCollection'
 * const moduleList = { Hero, CardCollection }
 *
 * // Render the Hero component from `moduleList` with `props`
 * <Component
 *   libComponents={moduleList}
 *   {{ component: 'Hero', ...props }}
 * />
 */
function Component({ libComponents, ...props }: ComponentProps) {
	const { component, editorID, type = "", parentEditorID } = props;
	const { renderer } = useSite();
	const isNavigation = useNavigation()?.isNavigation;
	const selectedEditorID =
		renderer === "editor" && typeof window !== "undefined"
			? Number.parseInt(localStorage.getItem("selectedID") || "0")
			: null;

	const LibComponent = getComponent(libComponents, {
		...props,
	});

	const isNavigationInnerModule =
		isNavigation && !["header", "footer"].includes(type);

	// AX
	if (renderer === "editor") {
		return (
			<SessionProvider>
				<ComponentWrapper
					selectedEditorID={selectedEditorID}
					isSelectionAllowed={!isNavigationInnerModule}
					editorID={editorID}
					component={component}
					type={type}
					parentEditorID={parentEditorID}
				>
					{LibComponent ? <LibComponent {...props} /> : <></>}
				</ComponentWrapper>
			</SessionProvider>
		);
	}

	// AX preview
	if (renderer === "preview")
		return (
			<SessionProvider>
				{LibComponent && <LibComponent {...props} />}
			</SessionProvider>
		);

	// CX
	return LibComponent && <LibComponent {...props} />;
}

function getComponent(
	// components es un objeto con key/value donde value es un componente de React
	components: Record<string, FC<Omit<ComponentProps, "libComponents">>>,
	// props pasadas al componente, no sabemos qué se pasará
	props: {
		component: string;
		[key: string]: unknown;
	},
) {
	const componentName = props.component;
	const isComponent = typeof components[componentName] !== "undefined";

	if (isComponent) {
		return components[componentName];
	}

	console.warn(
		`The component <${componentName}> doesn't exist inside ${JSON.stringify(
			Object.keys(components),
		)}`,
	);

	return null;
}

export { Component, getComponent };
