import { deepClone } from "@ax/helpers";
import { IStructuredDataContent, IStructuredData, ISchemaField, IStructuredDataCategory } from "@ax/types";

const prepareStructuredDataContent = (structuredDataContent: IStructuredDataContent | IStructuredDataCategory) => {
  return { ...structuredDataContent, content: structuredDataContent.content };
};

const updateCurrentDataContent = (getState: any, data: IStructuredDataContent) => {
  const {
    structuredData: { currentDataContent },
  } = getState();

  return currentDataContent.map((item: IStructuredDataContent) => {
    if (item.id === data.id) {
      item = data;
    }
    return item;
  });
};

const generateForm = (schema: any) => {
  const { fields } = schema;
  return fields.reduce((obj: Record<string, unknown>, field: any) => {
    return {
      ...obj,
      [field.key]: "",
    };
  }, {});
};

const getTaxonomies = (data: IStructuredData[]) => data.filter((item: IStructuredData) => item.taxonomy);
const getTypes = (data: IStructuredData[]) => data.filter((item: IStructuredData) => !item.taxonomy);
const filterStructuredDataByID = (data: any, id: string) => data.find((item: any) => item.id === id);

const evaluateDataComputedFields = (structuredData: any, fields: any[]) => {
  const updatedData = deepClone(structuredData);
  const data = {
    operation: "save",
    apiUrl: process.env.REACT_APP_API_ENDPOINT,
    publicApiUrl: process.env.REACT_APP_PUBLIC_API_ENDPOINT,
  };
  fields.forEach(async (field: ISchemaField) => {
    if (Object.prototype.hasOwnProperty.call(field, "computed")) {
      const computedFunction = eval(`(${field.computed})`);
      updatedData.content[field.key] = await computedFunction(structuredData.content, data);
    }
  });
  return updatedData;
};

export {
  prepareStructuredDataContent,
  updateCurrentDataContent,
  generateForm,
  getTaxonomies,
  getTypes,
  filterStructuredDataByID,
  evaluateDataComputedFields,
};
