import { formatLocaleDate } from "../functions";
import { usePage } from "./usePage";

/**
 * Format and translate a string date from a `DateField` (YY/MM/DD) responding
 * to different Locales from page context. Also support an option object
 * (Intl.DateTimeFormatOptions) to customize the output.
 *
 * @example
 * const { date } = useLocaleDate("2023/08/06")
 * // 6 Agosto 2023
 */
export function useLocaleDate(
	date?: string,
	options: Intl.DateTimeFormatOptions = {
		dateStyle: "long",
	},
) {
	const { ISOLocale: locale } = usePage();

	if (!date) {
		return { date: undefined, dateTime: undefined };
	}

	return formatLocaleDate(date, locale || "en-US", options);
}
